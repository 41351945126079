import {
  BehaviourReportsCpt,
  BehaviourSummaryCpt,
  AdministratorListCpt,
  PublicationRateTypesCpt,
  PublicationRatesCpt,
  PublicationsCpt,
  CreateUserCpt,
  ActiveUsersCpt,
  AdminMenuCpt,
  SearchUserCpt,
  ReconciliationsCpt,
  EditUserView,
  ApiKeyListCpt,
} from "./components";

const rootPath: string = "/admin";
const menu = () => ({ title: "Administration" });
export const adminRoutes = [
  {
    path: `${rootPath}`,
    redirect: `${rootPath}/users/search`,
  },
  {
    path: `${rootPath}/users/search`,
    name: "user-search",
    display: "User search",
    components: {
      leftmenu: AdminMenuCpt,
      content: SearchUserCpt,
    },
    props: {
      leftmenu: menu,
    },
    meta: { layout: "leftbar" },
  },

  {
    path: `${rootPath}/users/active`,
    name: "user-active",
    display: "Active users",
    components: {
      leftmenu: AdminMenuCpt,
      content: ActiveUsersCpt,
    },
    props: {
      leftmenu: menu,
      content: {
        filter: "active",
      },
    },
    meta: { layout: "leftbar" },
  },
  {
    path: `${rootPath}/apikeys`,
    name: "api-keys-active",
    display: "API Keys",
    components: {
      leftmenu: AdminMenuCpt,
      content: ApiKeyListCpt,
    },
    props: {
      leftmenu: menu,
      content: {
        filter: "active",
      },
    },
    meta: { layout: "leftbar" },
  },
  {
    path: `${rootPath}/users/usage`,
    name: "usage",
    display: "User sessions",
    components: {
      leftmenu: AdminMenuCpt,
      content: ActiveUsersCpt,
    },
    props: {
      leftmenu: menu,
      content: {
        filter: "usage",
      },
    },
    meta: { layout: "leftbar" },
  },
  {
    path: `${rootPath}/user/create`,
    name: "user-create",
    components: {
      leftmenu: AdminMenuCpt,
      content: CreateUserCpt,
    },
    props: {
      leftmenu: menu,
    },
    meta: { layout: "leftbar" },
  },
  {
    path: `${rootPath}/user/edit/:userProfileId`,
    name: "user-edit",
    components: {
      leftmenu: AdminMenuCpt,
      content: EditUserView,
    },
    props: {
      leftmenu: menu,
    },
    meta: { layout: "leftbar" },
  },
  {
    path: `${rootPath}/publications`,
    name: "publications",
    display: "Publications",
    components: {
      leftmenu: AdminMenuCpt,
      content: PublicationsCpt,
    },
    props: {
      leftmenu: menu,
    },
    meta: { layout: "leftbar" },
  },
  {
    path: `${rootPath}/publications/rates`,
    name: "publications-rates",
    display: "Publication Rates",
    components: {
      leftmenu: AdminMenuCpt,
      content: PublicationRatesCpt,
    },
    props: {
      leftmenu: menu,
    },
    meta: { layout: "leftbar" },
  },
  {
    path: `${rootPath}/publications/rate-types`,
    name: "rate-types",
    display: "Rate types",
    components: {
      leftmenu: AdminMenuCpt,
      content: PublicationRateTypesCpt,
    },
    props: {
      leftmenu: menu,
    },
    meta: { layout: "leftbar" },
  },
  {
    path: `${rootPath}/reconciliations`,
    name: "reconciliations",
    // display: "Rolling Sub Reconciliations",
    components: {
      leftmenu: AdminMenuCpt,
      content: ReconciliationsCpt,
    },
    props: {
      leftmenu: menu,
    },
    meta: { layout: "leftbar" },
  },
  {
    path: `${rootPath}/administrators`,
    name: "administrators",
    // display: "Administrators",
    components: {
      leftmenu: AdminMenuCpt,
      content: AdministratorListCpt,
    },
    props: {
      leftmenu: menu,
    },
    meta: { layout: "leftbar" },
  },

  {
    path: `${rootPath}/behaviour/summary`,
    name: "behaviour-summary",
    // display: "Behaviour summary",
    components: {
      leftmenu: AdminMenuCpt,
      content: BehaviourSummaryCpt,
    },
    props: {
      leftmenu: menu,
    },
    meta: { layout: "leftbar" },
  },
  {
    path: `${rootPath}/behaviour/reports`,
    name: "behaviour-reports",
    // display: "Behaviour reports",
    components: {
      leftmenu: AdminMenuCpt,
      content: BehaviourReportsCpt,
    },
    props: {
      leftmenu: menu,
    },
    meta: { layout: "leftbar" },
  },

];
