


































































































































import { Component, Vue, Prop } from "vue-property-decorator";

import { AccountAdminService } from "@/core/services";
import ApiKeyEditDialogCpt from "../ApiKeyEditDialogCpt.vue";

import AppVue from "@/AppVue.vue";
import moment from "moment";

export class Type {}

@Component({
  components: {
    ApiKeyEditDialogCpt: () => Promise.resolve(ApiKeyEditDialogCpt),
  },
})
export default class ApiKeyPanelCpt extends AppVue {
  @Prop({ default: 0 }) userId: number;

  loading: boolean = false;
  showApiKeyCreationDialog: boolean = false;
  apiKeyList: any[] = [];
  apiKey: any = {
    validUntil: null,
    companySearchEnabled: false,
  };
  editApiKey: any = {
    apiKeyId: null,
    validUntil: "",
    companySearchEnabled: false,
  };

  showEditApiKeyDialog: boolean = false;
  showEditApiKey(item: any) {
    this.editApiKey.apiKeyId = item.userApiKeyId;
    this.editApiKey.validUntil = moment(item.validUntil).local().format("YYYY-MM-DD");
    this.editApiKey.companySearchEnabled = item.companySearchEnabled;
    this.showEditApiKeyDialog = true;
  }

  getTypeForDate(date: any) {
    var test = moment(date);
    if (test.isBefore()) return "danger";
    if (test.add(30, "d").isBefore()) return "warning";
    return "success";
  }

  getIsExpired(date: any) {
    var test = moment(date);
    return test.isBefore();
  }

  async refresh() {
    this.loading = true;
    this.apiKeyList = await AccountAdminService.GetAllUserApiKeys(this.userId);
    this.loading = false;
    this.showEditApiKeyDialog = false;
  }

  async enableApiKey(userId: number, apiKeyId: number) {
    this.loading = true;
    await AccountAdminService.ActivateApiKey(userId, apiKeyId);
    this.refresh();
  }
  async disableApiKey(userId: number, apiKeyId: number) {
    this.loading = true;
    await AccountAdminService.DeactivateApiKey(userId, apiKeyId);
    this.refresh();
  }
  async createApiKey() {
    this.loading = true;
    this.showApiKeyCreationDialog = false;
    await AccountAdminService.CreateNewApiKey(this.userId, this.apiKey.validUntil, this.apiKey.companySearchEnabled);
    this.refresh();
    this.notify("New key generated", `A new key has been generated and emailed to the user`, "success");
  }
  async created() {
    this.apiKey.validUntil = moment.utc().add(1, "year");
  }

  async mounted() {
    this.refresh();
  }
}
