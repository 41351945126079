






























































































import { Component, Vue, Prop } from "vue-property-decorator";

import {} from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";
import moment from "moment";
import UserChartsCpt from "../charts/UserChartsCpt.vue";

export class Type {}

@Component({
  components: {
    UserChartsCpt: () => Promise.resolve(UserChartsCpt),
  },
})
export default class ActivityTabPanelCpt extends AppVue {
  @Prop({ default: false }) loading: boolean;
  @Prop({ default: "Activity" }) label: string;
  @Prop({ default: {} }) session: any;
  @Prop({ default: {} }) userStats: any;
  @Prop({ default: {} }) userTrackedTotals: any;
  @Prop({ default: {} }) userSavedSearchesTotals: any;
  @Prop({ default: "" }) userFullName: string;
  @Prop({ default: "stats" }) panelName: string;
  dailyStat = false;
  selectedChart = "bar";
  months = moment.monthsShort();
  years = [2019, 2020, 2021];
  selectedYear = moment().year();
  selectedMonth = moment().month() + 1;
  get isLoading() {
    return this.loading;
  }
  get userTrackedProjectsTotal() {
    return this.userTrackedTotals[0];
  }
  get userTrackedCompaniesTotal() {
    return this.userTrackedTotals[1];
  }
  get getUserSavedProjectSearches() {
    return this.userSavedSearchesTotals[0];
  }
  get getUserSavedCompanySearches() {
    return this.userSavedSearchesTotals[1];
  }
  get dateFormat() {
    if (!this.dailyStat) {
      return "MMM YYYY";
    }
    return "MMM Do YY";
  }
  changeTab() {
    this.$emit("change", {
      selectedYear: this.selectedYear,
      selectedMonth: this.selectedMonth,
      selectedChart: this.selectedChart,
      dailyStat: this.dailyStat,
    });
  }
  async created() {}

  async mounted() {
    const end = moment().year();
    const start = 2019;

    this.years = [...Array(end - start + 1).keys()].map((x) => start + x);
  }
}
