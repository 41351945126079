























































































































































import { Component, Vue, Prop } from "vue-property-decorator";

import { AlertService, ProfileModel } from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";

export class Type {}

@Component({
  components: {},
})
export default class TrackingAlertsCpt extends AppVue {
  @Prop({ default: false }) loading: boolean;
  @Prop({ default: {} as ProfileModel }) profile: ProfileModel;
  searchModel = {};
  trackingModel = {};
  get isLoading() {
    return this.loading;
  }

  async sendSearchAlerts(profile: ProfileModel) {
    this.searchModel = await AlertService.sendSearchAlerts(profile.userId);
  }
  async sendTrackingAlerts(profile: ProfileModel) {
    this.trackingModel = await AlertService.sendTrackingAlerts(profile.userId);
  }

  async created() {}

  async mounted() {}
}
